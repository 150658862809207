import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filename'
})
export class FileNamePipe implements PipeTransform {
  transform(value: string) {

    if (!value) {
      return value;
    }

    return value.split('/').pop().split('#')[0].split('?')[0];
  }
}